import { UpdateAssignmentPayload } from 'models/api-response';
import { NewAssignment, TimeSpent } from 'models/ui';
import { facadeApi } from 'helpers/api.helper';

export const getAssessment = (assessmentId: number) =>
  facadeApi.get(`/assignment/assessment/${assessmentId}`).then((res) => ({
    ...res,
    data: {
      eolsAssignmentId: res.data.assignmentId,
      eolsAssessmentId: res.data.id,
      assignmentType: res.data.assignmentType,
      simChartId: res.data.simChartId,
      studentId: res.data.userId,
      firstName: res.data.firstName,
      lastName: res.data.lastName,
      status: res.data.status,
      objectives: res.data.objectives,
      instructions: res.data.instructions
    }
  }));

export const startAssessment = (assessmentId: number) => facadeApi.put(`/assignment/assessment/${assessmentId}/start`);

export const createNewAssignment = (assignmentData: NewAssignment) => facadeApi.post('/assignment', { data: { ...assignmentData } });

export const getStudentAssignments = (courseId: number) => facadeApi.get(`/assignment/courseSectionId/${courseId}`);

export const getIsbnLocale = (isbn: string) => facadeApi.get(`/content/locale/${isbn}`);

export const submitAssessment = (assessmentId: number) => facadeApi.post(`/assignment/assessment/${assessmentId}/submission`);

export const getAssignmentSummary = (assignmentId: number) => facadeApi.get(`/assignment/${assignmentId}/summary`);

export const getSimChartAssignment = (assignmentId: number) => {
  console.log('getSimChartAssignment');
  return facadeApi.get(`/assignment/${assignmentId}`);
};

export const updateSimChartAssignment = (assignmentId: number, payload: UpdateAssignmentPayload) => facadeApi.put(`/assignment/${assignmentId}`, { data: { ...payload } });

export const getAssessmentByUserIdAndAssignmentId = (assignmentId: number, userId: number) => facadeApi.get(`/assignment/${assignmentId}/assessment?userId=${userId}`);

export const getOrCreateAssessmentByUserIdAndAssignmentId = async (assignmentId: number, userId: number) =>
  facadeApi.post(`/assignment/${assignmentId}/assessment?userId=${userId}`);

export const getAssessmentSubmissionSummary = async (assignmentId: number, userId: number) =>
  facadeApi.get(`/assignment/${assignmentId}/assessment/submissionSummary?userId=${userId}`);

export const getSessionParams = (linkId: string) => facadeApi.get(`/assignment/app-link/data/${linkId}`);

export const sendTimeSpent = (timeSpentData: TimeSpent) => facadeApi.post(`/assignment/time-spent`, { data: { ...timeSpentData } });

export const getTimeSpent = (assignmentId: number) => facadeApi.get(`/assignment/time-spent/${assignmentId}`);

export const getDrugInfoURL = (name: string) => facadeApi.get(`/assignment/ticurl/${name}`);

export const getCKNURL = () => facadeApi.get('/assignment/nursing/ticurl/');
