import produce from 'immer';
import { isEmpty, omit } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { v4 } from 'uuid';
import { ELSURLHelper } from '@els/els-ui-common-react';
import { ChartFragment, ChartFragmentRS, ChartFragmentsRS, ChartMetadataRS } from 'models/api-response';
import { FormFieldDataType, FormFieldInputType, FragmentType } from 'models/enum';
import { ChartComment, ChartMetaFormField, NavigationItem, PatientContext } from 'models/ui';
import { AppConstant, NAV_ID, ParentSections, RouteParams, RoutePath } from 'constants/app.constant';
import { apiHelper, chartHelper } from 'helpers';
import { navigationService } from 'services';

interface PlainRecordParams {
  formField: string;
  title: string;
  content: any;
  value?: any;
  contentId?: string;
  preventDelete?: boolean;
  linkedFormFieldIds?: string[];
  parentLabel?: string;
}

export const buildPlainRecord = (plainRecord: PlainRecordParams) => {
  return {
    id: v4(),
    active: true,
    ...plainRecord
  };
};

export const NAV_ID_HAS_SAME_METADATA = {
  [NAV_ID.ALLERGY_INFORMATION]: NAV_ID.ALLERGIES,
  [NAV_ID.NEWBORN_ASSESSMENT_SPECIAL]: NAV_ID.NEWBORN_ASSESSMENT
};

// fetch content and validation rules based on navigation id
export const fetchChartMetadata = (navId: string, patientContext: PatientContext): Promise<ChartMetadataRS> => {
  const newNavId = NAV_ID_HAS_SAME_METADATA[navId] ?? navId;
  const locale = patientContext?.locale || JSON.parse(localStorage.getItem(AppConstant.PERSIST_KEY))?.appState?.locale;
  let url = `/content/chart/meta/${newNavId}`;
  if (patientContext) {
    url += `?${queryString.stringify(patientContext)}`;
  } else if (locale) {
    url += `?locale=${locale}`;
  }
  return apiHelper.facadeApi.get(url);
};

export const saveFragment = (chartId: string, navId: string, fragment: object): Promise<ChartFragmentRS> =>
  apiHelper.facadeApi.post(`/chartpersistence/chart/${encodeURIComponent(chartId)}/fragment/${navId}`, { data: { ...fragment } });

export const loadFragments = ({ chartId, navIds = [], fragmentTypes = [FragmentType.CHARTING] }): Promise<ChartFragmentsRS> => {
  const baseUrl = `/chartpersistence/chart/${encodeURIComponent(chartId)}/fragments`;
  const query = { navIds, fragmentTypes };
  const url = queryString.stringifyUrl({ url: baseUrl, query }, { arrayFormat: 'comma', skipNull: true, skipEmptyString: true });

  return apiHelper.facadeApi.get(url);
};

export const deleteFragment = (chartId: string, fragmentId: string): Promise<void> =>
  apiHelper.facadeApi.delete(`/chartpersistence/chart/${encodeURIComponent(chartId)}/fragments`, { data: { fragmentIds: [fragmentId] } });

export const saveMultiFragments = (chartId: string, fragments: object[]): Promise<void> =>
  apiHelper.facadeApi.post(`/chartpersistence/chart/${encodeURIComponent(chartId)}/fragments`, { data: fragments });

export const loadAuthors = () => apiHelper.facadeApi.get('/content/authors');

export const lockFragments = ({ chartId, navIds, chartingAt }: { chartId: string; navIds: string[]; chartingAt: string }) => {
  const baseUrl = `/chartpersistence/chart/${encodeURIComponent(chartId)}/fragments/lock`;
  return apiHelper.facadeApi.patch(baseUrl, { data: { navIds, locked: true, chartingAt, completedAt: chartingAt } });
};

export const getChartingTime = (chartingTime: string) => chartingTime || moment().toISOString();

export const createBaseFragment = ({ fragmentId = '', chartingTime, hasCompleted = false }) => {
  const chartingAt = getChartingTime(chartingTime);
  let baseFragment = {
    fragmentId,
    fragmentType: FragmentType.CHARTING,
    chartingAt,
    completedAt: hasCompleted ? chartingAt : null
  };
  if (!fragmentId) {
    baseFragment = omit(baseFragment, 'fragmentId');
  }
  return baseFragment;
};

export const buildRecordFromField = (formField: ChartMetaFormField, customTitle?: string, customDisplayValue?: string, options?: Partial<PlainRecordParams>) => {
  if (!formField?.value) {
    return null;
  }

  let title = formField.label || formField.chartContent[0]?.label || '';
  let content = formField.value;
  let { value } = formField;
  let contentId = '';

  if (formField.inputType === FormFieldInputType.CHECK_BOX) {
    title = formField.chartContent[0].selected ? formField.chartContent[0]?.label : '';
    content = formField.chartContent[0].selected;
    value = formField.chartContent[0].value;
    contentId = formField.chartContent[0].id;
  }
  if (
    formField.inputType === FormFieldInputType.RADIO_CHOICE ||
    formField.inputType === FormFieldInputType.DROPDOWN ||
    formField.inputType === FormFieldInputType.MULTI_SELECT_RADIO
  ) {
    const selectedContent = formField.chartContent.find((contentItem) => !!contentItem.value && contentItem.selected);
    if (!selectedContent) {
      return null;
    }
    title = formField.label;
    content = selectedContent.label;
    value = selectedContent.value;
    contentId = selectedContent.id;
  }

  return buildPlainRecord({
    formField: formField.name,
    title: customTitle ?? title,
    content: customDisplayValue ?? content,
    value,
    contentId,
    parentLabel: formField.parentLabel,
    ...options
  });
};

export const buildPatientRecord = (
  formFieldMap: Map<string, ChartMetaFormField>,
  formFieldId: string,
  customTitle?: string,
  customDisplayValue?: string,
  options?: Partial<PlainRecordParams>
) => {
  return buildRecordFromField(formFieldMap.get(formFieldId), customTitle, customDisplayValue, options);
};

export const buildRecordsFromField = (formField: ChartMetaFormField, shouldKeepScaleStructure?: boolean, preventDelete?: boolean) => {
  if (!formField?.value) {
    return [];
  }

  if (formField.inputType === FormFieldInputType.MULTISELECT_DROPDOWN || formField.inputType === FormFieldInputType.CHECK_BOX) {
    return formField.chartContent
      .filter((content) => content.selected)
      .map((content) =>
        buildPlainRecord({
          formField: formField.name,
          title: content.label,
          content: true,
          value: content.value,
          contentId: content.id,
          preventDelete
        })
      );
  }
  return [];
};

export const buildPatientRecords = (
  formFieldMap: Map<string, ChartMetaFormField>,
  formFieldId: string,
  shouldKeepScaleStructure?: boolean,
  preventDelete?: boolean,
  removeEmptyRecords?: boolean
) => {
  const formField = formFieldMap.get(formFieldId);

  if (formField?.inputType === FormFieldInputType.SCALE) {
    if (shouldKeepScaleStructure) {
      return formField.chartContent
        .filter((content) => content.dataType !== FormFieldDataType.SCALE_ROLL_UP && content.dataType !== FormFieldDataType.SCALE_ROLL_UP_HIGHLIGHT)
        .map((content) => {
          const field = formFieldMap.get(content.formFieldId);
          return buildPlainRecord({
            formField: content.formFieldId,
            title: content.label,
            content: field?.extraData?.score,
            preventDelete
          });
        });
    }

    return formField.chartContent
      .filter((content) => {
        const scoreValue = formFieldMap.get(content.formFieldId)?.extraData?.score?.value;
        const isKept = removeEmptyRecords ? !isEmpty(scoreValue) : true;
        return content.dataType === FormFieldDataType.SCALE_QUESTION && isKept;
      })
      .map((content) => {
        const field = formFieldMap.get(content.formFieldId);
        return buildPlainRecord({
          formField: content.formFieldId,
          title: content.label || content.fieldLabel,
          content: field?.extraData?.score?.value,
          preventDelete
        });
      });
  }

  return buildRecordsFromField(formField, shouldKeepScaleStructure, preventDelete);
};

export const formatName = ({ firstName = '', lastName = '' }: { firstName?: string; lastName?: string }) => `${firstName?.substring(0, 1)} ${lastName}`.trim();

export const formatShortName = ({ firstName = '', lastName = '' }: { firstName?: string; lastName?: string }) =>
  `${firstName?.substring(0, 1).toUpperCase()}${lastName?.substring(0, 1).toUpperCase()}`;

export const systemAssessment = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeEmptyRecords: (record): any =>
    produce(record, (draft) => {
      draft.records = draft.records.map((newRecord) => ({ ...newRecord, records: newRecord.records.filter((item) => !!item) }));
      draft.records = draft.records.filter((item) => item.records?.length > 0);
    })
};

export const getComments = async (chartId: string, navIds: string[]) => {
  const chartIdParam = encodeURIComponent(chartId);
  const navIdsParam = navIds.join(',');
  const fragmentTypeParam = FragmentType.GRADE;
  const url = `/chartpersistence/chart/${chartIdParam}/fragments?navIds=${navIdsParam}&fragmentTypes=${fragmentTypeParam}`;
  return apiHelper.facadeApi.get(url);
};

export const saveComment = (chartId: string, fragmentId: string, navId: string, comment: string, linkedFragmentId?: string): Promise<ChartFragmentRS> => {
  let finalFragment = { fragmentId, fragmentType: FragmentType.GRADE, navElementId: navId, isActive: true, chartData: { comment }, linkedFragmentId };
  if (!fragmentId) {
    finalFragment = omit(finalFragment, 'fragmentId');
  }
  if (!linkedFragmentId) {
    finalFragment = omit(finalFragment, 'linkedFragmentId');
  }

  return saveFragment(chartId, navId, finalFragment);
};

export const getOverallComment = (chartId: string): Promise<ChartFragment> =>
  loadFragments({ chartId, navIds: [NAV_ID.SUBMISSION_OVERVIEW], fragmentTypes: [FragmentType.GRADE] }).then(({ data }) => (data.length ? data[0] : null));

export const saveOverallComment = (chartId: string, fragmentId: string, comment: string): Promise<ChartFragmentRS> => {
  let finalFragment = { fragmentId, fragmentType: FragmentType.GRADE, navElementId: NAV_ID.SUBMISSION_OVERVIEW, isActive: true, chartData: { comment } };
  if (!fragmentId) {
    finalFragment = omit(finalFragment, 'fragmentId');
  }

  return saveFragment(chartId, NAV_ID.SUBMISSION_OVERVIEW, finalFragment);
};

export const buildChartComments = async (assessmentId: string, chartId: string, menuItems: NavigationItem[], completedCharts: string[]): Promise<ChartComment[]> => {
  if (!menuItems?.length || !completedCharts?.length) {
    return Promise.resolve([]);
  }
  return getComments(chartId, completedCharts).then(({ data: commentFragments }) => {
    const navigationMap = navigationService.mapTreeToMap(menuItems, null);
    return completedCharts
      .map((navId) => {
        const contents = commentFragments.filter((commentFragment) => commentFragment.navElementId === navId).map((fragment) => fragment.chartData.comment);
        const createdAt = commentFragments.filter((commentFragment) => commentFragment.navElementId === navId).map((fragment) => fragment.createdAt)[0];
        const navigation = navigationMap.get(navId);
        if (!navigation) {
          return null;
        }
        const breadcrumbsItems = chartHelper.buildBreadcrumbItems(navigation.path, menuItems);
        const title = breadcrumbsItems.map((item) => item.text).join(' - ');

        let { path } = navigation;
        if (navId.startsWith(ParentSections.SYSTEM_ASSESSMENT)) {
          path = RoutePath.student.systemAssessment.savedSystemAssessments.replace(RouteParams.ASSESSMENT_ID, assessmentId);
        } else if (navId.startsWith(ParentSections.SPECIAL_CHARTS)) {
          path = RoutePath.student.specialCharts.savedSpecialCharts.replace(RouteParams.ASSESSMENT_ID, assessmentId);
        }

        return {
          id: navId,
          title,
          path,
          contents,
          createdAt
        };
      })
      .filter((item) => !!item);
  });
};

export const displayAuthorRecord = (): boolean => {
  const displayOverride = ELSURLHelper.getParameterByName('displayAuthorData');
  const linkId = localStorage.getItem(AppConstant.COOKIE_LINK_ID);
  return displayOverride || !linkId || linkId === 'undefined';
};
